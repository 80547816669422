import React, { useRef } from 'react'
import './Navbar.css'
import { AiFillHome } from 'react-icons/ai'
import { GiHamburgerMenu } from 'react-icons/gi'
import Sidebar from '../Sidebar/Sidebar'
import { token_url, get_logo } from '../../script/util'
import { useStateContext } from '../../context/StateContext'
import { IoArrowBack } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom'

const Navbar = ({ title, location }) => {
  const upload = useRef(false)
  const { isOpen, setisOpen } = useStateContext();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  let left;
  if (location === token_url(`${process.env.REACT_APP_STAG_URL}`)) {
    left = <Link to={location}><AiFillHome /></Link>;
  } else if (location === '/compupload') {
    upload.current = true
  }
  else {
    left = <Link to='/'><IoArrowBack /></Link>;
  }
  const navigate = useNavigate();
  const handleBackPress = () => {
    navigate(`/?id=${id}`)
  };

  return (
    <>
      <div className="navbar">
        {!upload.current && <div className="nav-left" onClick={handleBackPress}>{left}</div>}
        {upload.current && <div className='nav-left' ><img className='logoSize' src={get_logo(true)} /></div>}
        <div className="nav-middle">{title}</div>
        <div className="nav-right"><GiHamburgerMenu onClick={() => setisOpen(true)} /></div>
      </div>
      {
        isOpen && <Sidebar />
      }
    </>
  )
}

export default Navbar