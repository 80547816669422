import React, { createContext, useContext, useState } from 'react'

const context = createContext();

export const StateContext = ({ children }) => {
    const [isOpen, setisOpen] = useState(false)
    const [userDetails, setuserDetails] = useState(null)
    const [isLoggedin, setisLoggedin] = useState(false)
    
    return (
        <context.Provider value={{ isOpen, setisOpen ,userDetails, setuserDetails,isLoggedin, setisLoggedin}} >
            {children}
        </context.Provider>

    )
}

export const useStateContext = () => useContext(context);